import request from '@/utils/request';

export function code(params) {
  return request({
    url: '/api/insure/index/code',
    method: 'get',
    params,
  });
}
export function phoneCode(params) {
  return request({
    url: '/api/insure/index/phoneCode',
    method: 'get',
    params,
  });
}
export function information(data) {
  return request({
    url: '/api/insure/index/information',
    method: 'post',
    data,
  });
}
export function getInformation(params) {
  return request({
    url: '/api/insure/index/info/information',
    method: 'get',
    params,
  });
}
export function verify(params) {
  return request({
    url: '/api/insure/index/verify',
    method: 'post',
    params,
  });
}
export function insureCancel(data) {
  return request({
    url: '/api/insure/index/insureCancel',
    method: 'post',
    data,
  });
}
export function insureInvoice(data) {
  return request({
    url: '/api/insure/index/insureInvoice',
    method: 'post',
    data,
  });
}
export function applyInsureMoneyVerify(data) {
  return request({
    url: '/api/insure/index/applyInsureMoneyVerify',
    method: 'post',
    data,
  });
}
export function submitEnterprise(data) {
  return request({
    url: '/api/enterpriseInfo/submitEnterprise',
    method: 'post',
    data,
  });
}
export function getEnterprise(params) {
  return request({
    url: '/api/enterpriseInfo/getEnterprise',
    method: 'get',
    params,
  });
}

export default { code, phoneCode, information, getInformation, verify, insureCancel, insureInvoice, applyInsureMoneyVerify, submitEnterprise, getEnterprise };
